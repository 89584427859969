import React, { Children, useState, useEffect } from "react"
import { useForm, FormProvider, appendErrors } from "react-hook-form"
import { ContactGoogleForm } from "./ContactSub"
import { TextInput, Button, DatePicker, Select, Check } from "./ui"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios"
import dateFormat from "dateformat"
import { navigate } from "gatsby"
import { usePromiseTracker, trackPromise } from "react-promise-tracker"
import { Send, Mail, Phone, MapPin, Loader } from "react-feather"
import { motion } from "framer-motion"
import { ContactQuery_site_siteMetadata_contact } from "../pages/__generated__/ContactQuery"

const schema = yup.object().shape({
    name1: yup.string().required(`お客様のお名前を入力してください`),
    address: yup.string().required(`お客様のご住所を入力してください`),
    telephone: yup
        .string()
        .matches(
            /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
            "有効な電話番号を入力してください"
        )
        .required(`連絡先のお電話番号を入力してください`),
    email: yup
        .string()
        .email(`正しいメールアドレスを入力してください`)
        .required(`お客様のメールアドレスを入力してください`),
    Sdate: yup.string().required(`配達開始日を指定してください`),
    sub: yup.string().required(`どちらかを選択してください`),
    // paper: yup.string().required(`どれかを選択してください`),
    // other: yup.string().when("paper", {
    //     is: "その他",
    //     then: yup.string().required(`新聞の銘柄を入力してください`),
    // }),
    plan: yup.string().required(`どれかを選択してください`),
})

const IconRight = ({ spin = false }) => {
    if (spin) {
        return (
            <span
                className="spin"
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    animationDuration: "5s",
                }}
            >
                <Loader />
            </span>
        )
    }
    return <Send />
}

type FormData = {
    name1: string
    name2: string
    address: string
    telephone: string
    email: string
    Sdate: string
    sub: string
    paper: string
    other: string
    plan: string
    message: string
    type: string
    store: string
    sendmail: string
    tel: string
    storeAddress: string
    code: string
}

const Subscribe: React.FC<{
    data: ContactQuery_site_siteMetadata_contact
}> = ({ data }) => {
    const { errors, watch, ...methods } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            sub: "お試し",
            plan: "日本経済新聞　朝夕刊 (5,500円)",
        },
    })
    const [transactionState, setTransactionState] = useState(false)
    const { promiseInProgress } = usePromiseTracker()
    const sub = watch("sub")
    const paper = watch("paper")
    // const papercheck = paper.includes("その他")
    useEffect(() => {
        methods.setValue("type", "Subscribe")
        methods.setValue("store", "ニュースサービス日経大岡山")
        methods.setValue("sendmail", data.mail)
        methods.setValue("tel", data.phone)
        methods.setValue("storeAddress", data.address)
        methods.setValue("code", data.code)
    })
    const submit = values => {
        values.Sdate = dateFormat(values.Sdate, "isoDate")

        const GATEWAY_URL =
            "https://8ru9wphnlb.execute-api.ap-northeast-1.amazonaws.com/SendMail"
        trackPromise(
            fetch(GATEWAY_URL, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                body: JSON.stringify(values),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
                .then(response => {
                    if (response.ok) {
                        navigate(`/thanks`)
                    }
                })
                .catch(error => {
                    navigate(`/error`)
                })
        )
    }
    return (
        <FormProvider errors={errors} {...methods} watch={watch}>
            <h4 className="text-center text-2xl text-color-4">
                お試し・ご購読のお申込み
            </h4>

            <form
                method="POST"
                onSubmit={methods.handleSubmit(submit)}
                noValidate
            >
                <input type="hidden" name="type" ref={methods.register} />
                <input type="hidden" name="store" ref={methods.register} />
                <input type="hidden" name="sendmail" ref={methods.register} />
                <input type="hidden" name="tel" ref={methods.register} />
                <input
                    type="hidden"
                    name="storeAddress"
                    ref={methods.register}
                />
                <input type="hidden" name="code" ref={methods.register} />
                <TextInput
                    label="お名前・会社名(部署名)"
                    name="name1"
                    footer={errors.name1?.message}
                />
                <TextInput
                    label="担当者様のお名前"
                    name="name2"
                    footer={errors.name2?.message}
                />
                <TextInput
                    label="ご住所"
                    name="address"
                    footer={errors.address?.message}
                />
                <TextInput
                    label="お電話番号"
                    name="telephone"
                    footer={errors.telephone?.message}
                />
                <TextInput
                    label="メールアドレス"
                    name="email"
                    type="email"
                    footer={errors.email?.message}
                />
                <Select
                    label="「お試し」と「ご購読」どちらのお申し込みですか？"
                    name="sub"
                    footer={errors.sub?.message}
                    value={[
                        { id: 1, value: "お試し" },
                        { id: 2, value: "ご購読" },
                    ]}
                />

                <Select
                    label="商品をお選びください　()内は月額購読料（税込み）です"
                    name="plan"
                    footer={errors.plan?.message}
                    value={[
                        {
                            id: 1,
                            value: "日本経済新聞　朝夕刊 (5,500円)",
                        },
                        { id: 2, value: "日本経済新聞　朝刊のみ (4,800円)" },
                        { id: 3, value: "日経MJ (2,800円)" },
                        { id: 4, value: "毎日新聞 (4,900円)" },
                        { id: 5, value: "スポーツニッポン (3,700円)" },
                        { id: 6, value: "毎日小学生新聞 (1,750円)" },
                    ]}
                />

                {/* {papercheck && (
                    <TextInput
                        label="その他の新聞の銘柄を入力して下さい"
                        name="other"
                        footer={errors.other?.message}
                    />
                )} */}
                <DatePicker
                    label="いつから配達開始しますか？"
                    name="Sdate"
                    type="date"
                    min={new Date()}
                    footer={errors.Sdate?.message}
                />

                <TextInput label="通信欄" name="message" footer={""} />
                <div className="py-3 lg:p-4">
                    <Button
                        type="button,submit"
                        title="送信"
                        disabled={promiseInProgress}
                        iconRight={<IconRight spin={promiseInProgress} />}
                        aria-label="送信"
                    />
                </div>
            </form>
        </FormProvider>
    )
}

export default Subscribe
